import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageLayout } from "../layouts/page";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "legal-mentions"
    }}>{`Legal mentions`}</h1>
    <h2 {...{
      "id": "entity"
    }}>{`Entity`}</h2>
    <p>{`Lleed & Partners SàRL is a limited liability, Swiss law company, based at Rue de la cité 1, C/O Multiburo, 1204 Geneva, Switzerland. Its current share capital is of CHF 20,000.-.`}</p>
    <p>{`Lleed & Partners is registered in the Geneva companies register and Switzerland VAT register under identifier CHE-279.374.453 since August 31, 2020. Additional registration informations are available on `}<a parentName="p" {...{
        "href": "https://ge.ch/hrcintapp/externalCompanyReport.action?companyOfsUid=CHE-279.374.453&lang=EN"
      }}>{`ge.ch`}</a></p>
    <p>{`For any question regarding this website and its contents, please contact us using our `}<a parentName="p" {...{
        "href": "/contact/"
      }}>{`contact portal`}</a>{`.`}</p>
    <h2 {...{
      "id": "hosting"
    }}>{`Hosting`}</h2>
    <p>{`This website is hosted by Netlify, Inc., located at 2325 3rd Street, Suite 296, San Francisco, California 94107, United States. You may contact Netlify by sending correspondence to the foregoing address or by email at `}<a parentName="p" {...{
        "href": "mailto:support@netlify.com"
      }}>{`support@netlify.com`}</a>{`.`}</p>
    <h2 {...{
      "id": "intellectual-property"
    }}>{`Intellectual property`}</h2>
    <p>{`This website is covered by the `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode"
      }}>{`Creative Commons CC BY-NC-ND 4.0 license`}</a>{`. Contents and images may be covered by another license.`}</p>
    <p>{`For copyright infringement complaints, please contact us using our `}<a parentName="p" {...{
        "href": "/contact/"
      }}>{`contact portal`}</a>{`.`}</p>


    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      